import React, { useContext, useEffect } from "react";
import Thumbnail from '../../UIs/Thumbnail';
import '../../css/project-list.css';
import { projects } from '../../utils/models';
import NavContext from "../../contexts/NavContext";
 
function ProjectList(props) {

  /*const ProjectsContext = React.createContext(projects);*/

  //Update Navbar project title through Context
  const navContext = useContext(NavContext);
  useEffect(()=>{
    navContext.setCurrentProject('');
  });

  return (
      <main role="main" className="offset-nav">
        <ul className="projects">
          {projects.map((project, i) => {
            return <Thumbnail key={i} project={project} />
          })}
        </ul>
      </main>
  );
}
 
export default ProjectList;