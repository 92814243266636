import React, { useContext, useEffect } from "react";
import NavContext from "../contexts/NavContext";
import '../css/about.scss';
 
function About(props) {

  //Update Navbar project title through Context
  const navContext = useContext(NavContext);
  useEffect(()=>{
    navContext.setCurrentProject('');
  });

  return (    
    <div className="about">
      <main role="main" className="offset-nav">
        <section className="blurb">
          <div className="sm-12 lg-8 offset-lg-4-left">
            <p className="font-secondary">
              Don't fear change,<br/>
              who you are, will always<br/>
              be a part of who you become.<br/>
            </p>
          </div>
        </section>
        <section className="bio">
          <div className="sm-12 lg-4 title">
            <h3 className="active">Bio</h3>
          </div>
          <div className="sm-12 lg-8 content">
            <p>I have a passion for graphic arts and illustrations.</p>
            {/* <p>
              Graphic Designer and Web Developer, with a passion for graphic arts and illustration.
              I have experience collaborating within cross-functional teams .
              Proactive, diplomatic natured, with a strong belief in knowledge sharing 
              and enabling communication as a path to success. 
            </p>
              <p>
              As a learner, I find the process, rather than the outcome, more exciting, so I am attracted to challenging endeavours. 
              It allows to actively build my skill set, constantly practice and improve myself. 
              I look forward to have art take an active part in my life, 
              in a way I can express new ideas creatively.
              Aiming to be a great designer. Ready to take action.
            </p> */}
          </div>
        </section>
        <section>
          <div className="sm-12 lg-4 title">
            <h3 className="active">Education</h3>
          </div>
          <div className="sm-12 lg-8 content">
            <div className="wrapper">
              <div>
                <p>Graphic Design for Print and Web</p>
                <p className="font-secondary">Humber College, Toronto, 2020</p>
              </div>
              {/* <div>
                <p>Graphic Design Specialization</p>
                <p className="font-secondary">CalArts, Online, 2018</p>
              </div> */}
              <div>
                <p>Computer Science</p>
                <p className="font-secondary">PUC Minas, Belo Horizonte, 2016</p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="sm-12 lg-4 title">
            <h3 className="active">Skills</h3>
          </div>
          <div className="sm-12 lg-8 content">
            <p>
              Illustrations, Adobe Creative Cloud (Photoshop, Illustrator, InDesign), 
              Web Design & Development, Figma, Print Production, Typesetting, Typography
              HTML5, CSS3, Javascript, React, Agile
              
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}
 
export default About;