import React from "react";
import '../css/footer.scss';
 
function Footer(props) {
  return (
    <div className="footer">
      <footer className="mobile">
        <div className="separator-container">
          <span className="separator"></span>
        </div>
        <ul>
          {/*<li><a href="https://www.artstation.com/ad-dubiela" target="_blank" rel="noreferrer"><i className="fab fa-artstation" alt="Artstation Icon"></i></a></li>*/}
          <li><a href="https://www.linkedin.com/in/adriano-dubiela/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin" alt="Linkedin Icon"></i></a></li>
          <li><a href="https://www.instagram.com/adri.dubi/" target="_blank" rel="noreferrer"><i className="fab fa-instagram" alt="Instagram Icon"></i></a></li>
        </ul>
        <p className="footnote">Adriano Dubiela © 2024 All Rights Reserved</p>
      </footer>
    </div>
  );
}
 
export default Footer;