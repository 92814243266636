class Project {

    /* TS Description
    urlPath: String;  Page url for routing 
    thumbFileName: String;  Image thumbnail name on the project
    title: String;
    category: String;
    role: String;
    client: String;
    date: String;
    description: String[];
    featured: Boolean = false; Features project at Home
    */
   /*urlPath: String, thumbFileName: String, title: String, category: String, role: String, client: String, date: String, description: String[], featured?: Boolean*/
    constructor(urlPath, thumbFileName, title, category, role, client, date, description, url, featured) {
        this.urlPath = urlPath; 
        this.thumbFileName = thumbFileName; 
        this.title = title;
        this.category = category;
        this.role = role;
        this.client = client;
        this.date = date;
        this.description = description;
        this.url = url ? url : '';
        this.featured =  featured? featured : false;
    }
}

export const projects = [
    new Project(//#0
        'self-portrait',
        'thumb-self-portrait.jpg',
        'Self Portrait Animation',
        'Vector Illustration',
        'Illustrator',
        'Personal Project',
        'Apr. 2024',
        [
        'Sketch and vectorized self portrait illustration, exploring simple animations.',
        'Created in Adobe Illustrator and After Effects.'
        ],
        '',
        true
        ),
    new Project(//#0
        'ecosolver',
        'thumb-ecosolver.jpg',
        'Ecosolver',
        'Social Media',
        'Designer',
        'Ecosolver',
        'Aug. 2020',
        [
        'These are some illustrations I created for Ecosolver.', 
        'Upon receiving a prompt, after doing some research on the topic, I usually like to start out exploring ideas with a quick brainstorm map.',
        'I may then decide to start gathering references I would like to use, or even shoot some of my own. Even though a piece may end up being very imaginative, when there\'s a ground in reality, it starts to gain depth.',
        'I then start with small thumbnails in traditional media, moving on to larger ones, until eventually everything becomes digital.',
        'My goal was to keep everything loose. That way I decrease the overall time spent on a piece and can reiterate on a step upon feedback from the team. So everything can change around and be improved, while also being very experimental.'
        ],
        'instagram.com/ecosolvercanada/',
        true
        ),
    new Project(//#2
        'fruit-fighters',
        'thumb-fruit-fighters.jpg',
        'Fruit Fighters',
        'Character Art',
        'Character Concept',
        'Personal Project',
        'Mar. 2023',
        [
        'Character concepts I created in a personal project, in partnership with Patricia Mayumi (@patmayumi).', 
        'The goal of the project was to create a stylized 3D scene, where a couple of fruit-like characters posed in a fighting rink, alluding to the Street Fighter game series.',
        'The biggest challenge was time, so the designs had to be simple, in order to be achieved in the desired timeline. I had so much fun imagining the different characters that we couldn\'t resist but to create a whole roster of fighters, which led to the creation of extra player selection screens, portraits and close ups!',
        'I chose different fruits that had an interesting contrast among themselves based on shape, and started to imagine what their personalities, relationships and stories would be like. Even though a carrot is not a fruit, adding it to the roster with a "do not carrot" attitude made the group more interesting, and strengthened its personality. The \'Corn Luchador\' character was scraped out for practical reasons, and an orange referee was added in its place.', //'What type of fighting styles would they use? Perhaps there was a relationship formed between some of them, such as rivalry or mentorship? Would the props they carry be simple enough to be modeled quickly and reuse, while still relating to their personas? These were some questions I asked myself as I imagined their story.',
        // 'Even though a carrot is not a fruit, adding it to the roster with a "do not carrot" attitude made the group more interesting, and strengthened its personality. The \'Corn Luchador\' character was scraped out for practical reasons, and an orange referee was added in its place', //'and to streghten the uniqueness of the carrot as the sole vegetable. An orange referee was added in its place.',
        // 'More shots and scenes can be seen at https://www.artstation.com/artwork/blaXwv.'
        ],
        'artstation.com/artwork/blaXwv',
        true
        ),
    new Project(//#1
        'walrus',
        'thumb-walrus.jpg',
        'The Walrus Editorial',
        'Magazine Editorial',
        'Designer',
        'Program Project',
        'Nov. 2019',
        [
        'Magazine editorial, for a Graphic Design program. Design and typeset of an article and front cover.', 
        'Given the text of an unformatted article, the goal was to print an 8 page magazine booklet. I decided to fit the article on The Walrus format, by studying its publishing style. There was research on the magazine specs as well as a deep analysis of typefaces, spacings and minor details that make it unique.', 'Created with Adobe InDesign and Illustrator. '
        ],
        ''
        ),
    new Project(//#5
        'saru',
        'thumb-saru.jpg',
        'Mr. Saru Painting',
        'Digital Painting',
        'Illustrator',
        'Personal Project',
        'Mar. 2018',
        [
        'Fan art for the show Star Trek: Discovery.',
        'Digital painting of the profile of an existing character. Study of values, and experimentation with a custom made paint texture brush.',
        'Created with Adobe Photoshop.'
        ],
        '',
        true
        ),
    new Project(//#6
        'otherworldly-journey',
        'thumb-otherworldly-journey.jpg',
        'Otherworldly Journey',
        'Album Concept',
        'Designer',
        'Program Project',
        'Nov. 2019',
        [
        'Concept of a fictional music album for the band Siamés. Designed a CD jewel case packaging to print, alongside an album website. ',
        'The project aimed to draw inspiration on the band\'s identity, while still steering to different experimental directions. The concept presents a red hooded character traveling across a variety of different scenarios, that are underlined by the track titles, while at the same time hinting at an unified plot. The website reused some graphics of the cd with a different approach.'
        ],
        ''
        ),
    new Project(//#7
        'freedom-flight',
        'thumb-freedom-flight.jpg',
        'Freedom\'s Flight',
        'Vector Illustration',
        'Illustrator',
        'Program Project',
        'Oct. 2019',
        [
        'Originally an illustration for a T-Shirt design, created for a Graphic Design program. Designed as a vector graphic for multi purpose use and print ready.',
        'The bird rising from the blank canvas and breaking the landscape\'s frame is intended to inspire; actions can bring color to the world if you\'re willing to change the pattern.',
        'The art got selected by Humber Galleries and printed to be displayed on the walls of Humber Lakeshore Campus, on January 2020.',
        'Made in Adobe Illustrator.'
        ],
        'humbergalleries.ca/adriano-dubiela',
        true
        ),
    new Project(//#4
        'burger-campaign',
        'thumb-burger-campaign.jpg',
        'Burger Campaign',
        'Ad Campaign',
        'Designer',
        'Program Project',
        'Nov. 2019',
        [
        '3 Ad campaign for an established brand, originally designed for a magazine format. Concept of the campaign creative brief and design to print.',
        'The concept consisted in analyzing the brand\'s position in the market and defining the best approach to reach the target audience.',
        'Created with Adobe Photoshop and Indesign.'
        ],
        '',
        true
        ),
    new Project(//#3
        'ad-astra-poster',
        'thumb-ad-astra-poster.jpg',
        'Ad Astra Poster',
        'Movie Poster',
        'Designer',
        'Program Project',
        'Sep. 2019',
        [
        'Poster for the movie Ad Astra, created as part of a Graphic Design program. Intended to explore photo manipulation tools, blending modes and press ready production.',
        'The poster was developed prior to the release of the movie in theaters, I had only read a short synopsis of the story by then. Only royalty free photos were used, photographers are attributed on the piece.',
        'Created with Adobe Photoshop.'
        ],
        '',
        true
        ),

    /*new Project(//#7
        'ps4-ad',
        'thumb-ps4-ad.jpg',
        'PS4 Ad',
        'Vector Illustration',
        'Designer',
        'Program Project',
        'Nov. 2019',
        [
        'Vector drawing of a controller, flavored as an ad.',
        'Focus on the exploration of different tools in Illustrator, rather than following pre-established brand identities.',
        'The art got selected by Humber Galleries and printed to be displayed on the walls of Humber Lakeshore Campus, on January 2020.',
        'Made in Adobe Illustrator.'
        ],
        ''
        ),*/
    new Project(//#8
        'mood-bee',
        'thumb-mood-bee.jpg',
        'Mood Bee',
        'Mobile App Design',
        'Designer',
        'Program Project',
        'Mar. 2020',
        [
        'Design and prototype of a meditation app, for guided lessons or self tracked sessions.',
        'Created icons, user personas, site map, and interactive protype.',
        'Made with Adobe XD, Illustrator and Photoshop.'
        ],
        ''
        ),
    new Project(//#9
        'kuma',
        'thumb-kuma.jpg',
        'Kuma Bento',
        'Brochure & Website',
        'Designer',
        'Program Project',
        'Jan. 2020',
        [
        'Concept and design of a custom T-Fold brochure, for a company that sells family friendly lunch boxes, with custom shaped meals. The accompanying website is a simple responsive single page scroller.',
        'Creation of brand concept, logo, icons, backgrounds and final press ready file. Responsive product page was bootstrapped with Materialize framework. Playful experimentation with css, through background blending modes, custom shaped clip paths and colorful animated gradients clipped on text.',
        'Created with Adobe InDesign and Photoshop.'
        ],
        '',
        ),
    new Project(//#10
        'surge',
        'thumb-surge.jpg',
        'Surge Fitness',
        'Branding & Editorial',
        'Designer',
        'Program Project',
        'Apr. 2020',
        [
        'Typesetting and design of a lengthy fitness manual, alongside the brand identity of a gym.',
        'Creation of logo, patterns, style guide. Information architecture of an extensive manuscript, organized in a printed handbook style manual, and accessible ready pdf.',
        'Created with Adobe InDesign, Photoshop and Illustrator.'
        ],
        '',
        ),
    new Project(//#11
        'fabled-seeds',
        'thumb-fabled-seeds.jpg',
        'Fabled Seeds',
        'Catalogue Editorial',
        'Designer',
        'Program Project',
        'Apr. 2020',
        [
        'Design of a catalogue and logo for a heirloom seed company. Creation of print ready files, as well as digital interactive pdf with accessibility in mind.',
        'Information architecture of the contents, layout, typesetting and design of imagery. The digital alternate is an accessible ready pdf, with an interactive order page that contains custom scripts to calculate and display pricing, alongside some initial input formatting.',
        'Created with Adobe InDesign and Photoshop.'
        ],
        '',
        ),
    new Project(//#12
        '3d-experiments',
        'thumb-3d-experiments.jpg',
        '3D Experiments',
        'Blender',
        'Designer',
        'Personal Projects',
        '2022–2023',
        [
        'Assorted learning experiments in 3D, based on courses, workshops and tutorials.',
        'Created with Blender.'
        ],
        '',
        ),
    new Project(//#13
        'sinking-dragon',
        'thumb-sinking-dragon.jpg',
        'Sinking Dragon',
        'Package Design',
        'Designer',
        'Program Project',
        'Apr. 2020',
        [
        'Tea box packaging design and print production.',
        'Created with Adobe Illustrator and Photoshop. '
        ],
        ''
        )
    ];