export default class Utils {
    static createInvalidMsg(element) {
    let invalidMsgElement = document.createElement('span');
    invalidMsgElement.className = "invalid";
    invalidMsgElement.id = element.name + "-invalid";
    this.insertAfter(invalidMsgElement, element);
    return invalidMsgElement;
  }
  
  static insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }
  
  static testEmail(email) {
  // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  static testName(name) {
    const re = /^[a-zA-ZçÇãâáàÃÂÁÀêéèÊÉÈíìîÎÍÌõôóòÕÔÓÒûúùÛÚÙ][a-zA-ZçÇãâáàÃÂÁÀêéèÊÉÈíìîÎÍÌõôóòÕÔÓÒûúùÛÚÙ\s]*$/;
    return re.test(name);
  }

    // Removes spacing from the beginning, end, as well as double spacings in the middle of a name string
    static formatName(name) {
        // Removes spacing in the beginning
        while ((name.indexOf(' ') === 0) && (name.length > 0)) {
            name = name.substr(1);
        }
        // Removes spacing at the end
        while ((name.lastIndexOf(' ') === (name.length - 1)) && (name.length > 0)) {
            name = name.slice(0, -1);
        }
        // Removes double spacing in the middle
        while (name.indexOf('  ') !== -1) {
            name = name.slice(0, name.indexOf('  ')) + name.slice(name.indexOf('  ') + 1);
        }
        return name;
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static deleteElement(element) {
        if(element) {
            element.parentNode.removeChild(element);
        }
    }
}
  