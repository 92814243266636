import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import '../../css/project.scss';
import NavContext from "../../contexts/NavContext";

function importAll(r) {
  return r.keys().map(r);
}
 
function Project(props) {
    let project = props.location.state;
    let projectImgs = null;
    if (!project) {
        let p = require('../../utils/models').projects;
        project = p.find(project => {return project.urlPath === props.match.params.path});
    };

    if(project) {
      //Import all images from a folder
      //const imgFolder = '../../imgs/' + project.urlPath +'/'; // Webpack's require.context only allow static path, can't use dynamic path
      const images = importAll(require.context('../../imgs/', true, /\.(png|jpe?g|svg|gif|mp4)$/));
      projectImgs = images.filter(img => {return img.includes(project.urlPath) && !img.includes('thumb')});
    }
    
    //Update Navbar project title through Context
    const navContext = useContext(NavContext);
    useEffect(()=>{ 
      if(project) {
        navContext.setCurrentProject(project.title);
      }});

    // TODO: Function to enable keyboard accessibility to image zoom through input
    // clicks on checkbox when focused and enter is pressed
    // needs onKeyUp={zoomHandler} to be set on label, and to manage input references
    function zoomHandler(e, inputRef) {
      if(e.code === "Enter")
        inputRef.click();
    }
    
  return (
      project ?
      <div className="project">
        <main role="main" className="offset-nav">
          <div className="title mobile">
            <h1>{project.title}</h1>
            <h2 className="font-secondary">{project.category}</h2>
            {/*<a href="#description">description v</a>*/}
          </div>
          <ul className="gallery sm-12 xlg-7">
          {projectImgs.map((img, i) => {
            return (
              <li key={i}>
                <label className="check-zoom" for={`check-zoom-${i}`} 
                // tabindex="0" aria-label="Press to zoom in and out of image"
                >
                  <input type="checkbox" id={`check-zoom-${i}`} hidden></input>
                  <img src={img} alt={`${project.title} project piece ${i}`}/>
                </label>
              </li>
            )
          })}
          </ul>
        </main>

        <section id="description" className="offset-nav">
          <div className="wrapper sm-12 xlg-5">
            <div className="container">
              
              <div>

                <div className="title desktop">
                  <h1>{project.title}</h1>
                  <h2 className="font-secondary">{project.category}</h2>
                </div>
                <div className="description">
                  {project.description.map((paragraph, i)=>{
                  return <p key={i}>{paragraph}</p>
                  })}
                </div>
                <div className="separator-container">
                  <span className="separator"></span>
                </div>
                <div className="details">
                  <div className="head sm-4">
                    <h3>Role</h3>
                    <h3>Client</h3>
                    <h3>Date</h3>
                    {project.url && <h3>Url</h3>}
                  </div>
                  <div className="info sm-8">
                    <div>
                      <p>{project.role}</p>
                      <p>{project.client}</p>
                      <p>{project.date}</p>
                      {project.url && <p><a href={"https://www." + project.url} target="_blank">{project.url}</a></p>}
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </section>

      </div>
      :
      <Redirect to="/" />
  );
}
 
export default Project;