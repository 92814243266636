import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import '../css/thumbnail.scss'
 
function Thumbnail(props) {

  const [thumbImg, setThumbImg] = useState(null);

  const loadImg = async ()=> {
    const img = await import('../imgs/'+props.project.thumbFileName);
    setThumbImg(img.default);
  }

  useEffect(()=>{
    loadImg();
  });

  /**
   * Notes:
   * require() can be used in place of async/await import()
   * straight to the <img src> path to avoid useState/useEffect hooks
   * but import() is recommended for Code Splitting in modern apps
   */
  
  return (
      <div className="thumbnail">
        <li className="thumb sm-6 lg-4">
            <Link to={{ pathname: `/projects/${props.project.urlPath}`, state: props.project}}>
                {thumbImg ? <img src={thumbImg} alt={`${props.project.title} Thumbnail`} /> : <span>loading...</span>}
                <div className="title">
                    <h2>{props.project.title}</h2>
                    <p>{props.project.category}</p>
                </div>
            </Link>
        </li>
      </div>
  );
}
 
export default Thumbnail;