import React, { useContext } from "react";
import { Link, NavLink } from 'react-router-dom';
import NavContext from "../contexts/NavContext";
import '../css/navbar.scss';
import '../css/footer.scss';
import icon from '../imgs/icon-ad.png';
import wordmark from '../imgs/wordmark-ad.png';
import wordmarkGD from '../imgs/wordmark-adgd.png';
 
function Navbar(props) {

  const navContext = useContext(NavContext);

  return (
    <div className="navbar footer">
      <nav role="navigation">
          <img className="icon-ad desktop" src={icon} alt="Bird Icon" />
          <Link to="/"><img className="wordmark-adgd desktop" src={wordmarkGD} alt="Adriano Dubiela Graphic Design Wordmark" /></Link>
          <Link to="/"><img className="wordmark-ad mobile" src={wordmark} alt="Adriano Dubiela Graphic Design Wordmark" /></Link>
          <div className="separator-container">
            <span className="separator"></span>
          </div>
          <ul>
              <li><NavLink exact to="/" activeClassName="active">Work</NavLink></li>
              { navContext.currentProject ? <li className="desktop"><a href="#top" className="active">{navContext.currentProject}</a></li> : '' }
              <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
              <li><NavLink to="/contact" activeClassName="active">Contact</NavLink></li>
          </ul>
          <footer className="desktop">
            <div className="separator-container">
              <span className="separator"></span>
            </div>
            <ul>
              {/*<li><a href="https://www.artstation.com/ad-dubiela" target="_blank" rel="noreferrer"><i className="fab fa-artstation" alt="Artstation Icon"></i></a></li>*/}
              <li><a href="https://www.linkedin.com/in/adriano-dubiela/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin" alt="Linkedin Icon"></i></a></li>
              <li><a href="https://www.instagram.com/adri.dubi/" target="_blank" rel="noreferrer"><i className="fab fa-instagram" alt="Instagram Icon"></i></a></li>
            </ul>
            <p className="footnote">Adriano Dubiela © 2024 All Rights Reserved</p>
          </footer>
      </nav>
    </div>
  );
}
 
export default Navbar;