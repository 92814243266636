import React, { useContext, useEffect } from "react"
import { Redirect } from "react-router-dom";
import NavContext from "../contexts/NavContext";
import '../css/home.scss'
 
function NotFound(props) {

//Update Navbar project title through Context
const navContext = useContext(NavContext);
useEffect(()=>{
  navContext.setCurrentProject('');
});

  return (
    <Redirect to="/" />
  );
}
 
export default NotFound;