import React from "react";
import NavContext from "../contexts/NavContext";
import '../css/contact.scss';
import Utils from '../utils/Utils'
import ReCAPTCHA from 'react-google-recaptcha';
 
class Contact extends React.Component {

  static contextType = NavContext;

  constructor(props) {
    super(props);
    this.contactForm = React.createRef();
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
    this.messageInput = React.createRef();
    this.submitButton = React.createRef();
    this.submitMsg = React.createRef();
    this.captchaCheck = false;
  }

  componentDidMount() {
    //Update Navbar project title through Context
    this.context.setCurrentProject('');
    //this.submitMsg.current.innerHTML = 'Please fill out all fields and complete the Captcha to send your message.';
  }

  onSubmit(event) {
    event.preventDefault();
    const formData = new FormData(this.contactForm.current);
    fetch(process.env.PUBLIC_URL + '/php/contact-handler.php', { method: 'post', body: formData})
    .then(response => {
      console.log('response:');console.log(response);console.log('response.text():');console.log(response.text());
      this.submitMsg.current.innerHTML = "Message sent, thank you!";
      this.submitMsg.current.className = 'success';
      this.submitMsg.current.style.display = 'block';
      window.alert('Thank you for reaching out ' + Utils.capitalizeFirstLetter(Utils.formatName(this.nameInput.current.value)) + ', I\'ll get back to you as soon as possible.');
    }).catch(error => {
      this.submitMsg.current.innerHTML = "There was an error sending your message, please try again later.";
      this.submitMsg.current.className = 'invalid';
      this.submitMsg.current.style.display = 'block';
      console.log('inside CATCH');//console.log(error);
    })
  }

  validate(inputElement) {
    inputElement = inputElement.current; //fix for uncontrolled component
    this.testSubmitVisibility();
    let invalidMsg = document.getElementById(inputElement.name + "-invalid");
    if(!inputElement.value) {
    invalidMsg = !invalidMsg ? Utils.createInvalidMsg(inputElement) : invalidMsg;
    invalidMsg.innerHTML = "* Please fill out your " + inputElement.name + ".";
    } else if (inputElement.name === "email") {
        if (!Utils.testEmail(inputElement.value)) {
          invalidMsg = !invalidMsg ? Utils.createInvalidMsg(inputElement) : invalidMsg;
          invalidMsg.innerHTML = "* Invalid email format."
        } else {
          inputElement.value = String(inputElement.value).toLowerCase();
          Utils.deleteElement(invalidMsg);
        }
    } else if (inputElement.name === "name") {
      inputElement.value = Utils.formatName(inputElement.value);
        if(!Utils.testName(inputElement.value)) {
          invalidMsg = !invalidMsg ? Utils.createInvalidMsg(inputElement) : invalidMsg;
          invalidMsg.innerHTML = "* Please fill in your name with letters and spaces."
        } else {
          Utils.deleteElement(invalidMsg);
        }
    } else {
      Utils.deleteElement(invalidMsg);
    }
 }

  toggleSubmitVisibility(toggle) {
    this.submitButton.current.style.display = toggle ?  'inline-block' : 'none';
    this.submitMsg.current.style.display = toggle ? 'none' : 'block';
    this.submitMsg.current.innerHTML = 'Please fill out all fields and complete the Captcha to send your message.';
    this.submitMsg.current.className = 'invalid';
}

 testSubmitVisibility() {
   Utils.testName(Utils.formatName(this.nameInput.current.value)) && Utils.testEmail(this.emailInput.current.value) && this.messageInput.current.value && this.captchaCheck ?
    this.toggleSubmitVisibility(true) : this.toggleSubmitVisibility(false);
}

  // Prevents Enter key and focuses on next input
  handleEnter(event, targetElementName) {
    if(event.charCode === 13) {
      event.preventDefault();
      document.getElementsByName(targetElementName)[0].focus();
    }
  }

  toggleCaptcha(toggle) {
    this.captchaCheck = toggle;
    //console.log('value of captchaCheck');
    //console.log(this.captchaCheck);
    this.testSubmitVisibility();
  }

  render() {
    return (
      <div className="contact">
        <main role="main" className="offset-nav">
          <div className="header">
            <h1 className="font-secondary">Let's work together!</h1>
            {/* <p>Email me at <strong><em>contact@adrianodubiela.com</em></strong> or simply fill out the form below:</p> */}
            <p>This page will be restructured, please reach out through <a href="https://www.linkedin.com/in/adriano-dubiela/" target="_blank">LinkedIn</a>.</p>
            <p>Cheers!</p>
          </div>
          <section>
            <form method="post" id="contactForm" ref={this.contactForm}>
              <div className="field">
                <label>Name*</label>
                <input type="text" placeholder="Name" name="name" maxLength="40" required ref={this.nameInput} onBlur={()=>this.validate(this.nameInput)} onKeyPress={(e)=>this.handleEnter(e, 'email')}/>{/**/}
              </div>
              <div className="field">
                <label>Email*</label>
                <input type="text" placeholder="Email" name="email" maxLength="45" required ref={this.emailInput} onBlur={()=>this.validate(this.emailInput)} onKeyPress={(e)=>this.handleEnter(e, 'message')}/>{/**/}
              </div>
              {/*</div>
                <label>Subject*</label>
                <input type="text" placeholder="Subject" name="subject" maxLength="85" required />
              </div>*/}
              <div className="field">
                <label>Message*</label>
                <textarea placeholder="Message" name="message" maxLength="1500" required ref={this.messageInput} onBlur={()=>this.validate(this.messageInput)}></textarea>
              </div>
              {/*<ReCAPTCHA className="g-recaptcha" sitekey="6LeJNPEUAAAAACipsI2FrsHgYQnwXiZ0OcpJLvTa" onChange={()=>this.toggleRecaptcha(true)} onExpired={()=>this.toggleRecaptcha(false)} />*/}
              <div className="btn">
                <span className="invalid" id="submit-msg" ref={this.submitMsg}>Ops! Looks like the form is temporarily out of order, but you can still reach me through the information on top.</span>
                <button className="font-secondary" type="submit" ref={this.submitButton}>Send!</button>
              </div>
            </form>
          </section>
        </main>
      </div>
    );
  }
}
 
export default Contact;