
import { useState } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'; 
import './css/App.css';
//import Home from './pages/Home';
import About from './pages/About';
import Contact from "./pages/Contact";
import ProjectList from './pages/projects/ProjectList';
import Project from './pages/projects/Project';
import Navbar from './UIs/Navbar';
import Footer from './UIs/Footer';
import NotFound from "./pages/NotFound";
import NavContext from "./contexts/NavContext";

function App() {

  //Default state provided through Context
 const [currentProject, setCurrentProject] = useState('');

  return (
    <BrowserRouter>
      <NavContext.Provider value={{currentProject, setCurrentProject}}>
          <Navbar/>
          <Switch>
            {/*<Route exact path="/" component={Home} />*/}
            {/*<Route exact path="/projects" component={ProjectList} />*/}
            <Route exact path="/" component={ProjectList} />
            <Route path="/projects/:path" component={Project} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route component={NotFound} />
          </Switch>
          <Footer/>
        </NavContext.Provider>
    </BrowserRouter>
  );
}

export default App;
